import React from 'react';

const MedugoSVG = () => {
  return (
    <div>
      <svg width={150} height={50} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1200 400">
        <defs>
          <style>
            {`.cls-1 { fill: #49c1c2; }`}
            {`.cls-2 { fill: #fff; }`}
            {`.cls-3 { fill: #41586e; }`}
            {`.cls-4 { fill: url(#linear-gradient); }`}
          </style>
          <linearGradient id="linear-gradient" x1="434.51" y1="-8.76" x2="1004.25" y2="-8.76" gradientTransform="translate(0 156.76) scale(1 -1)" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#49c1c2" stopOpacity="0" />
            <stop offset="1" stopColor="#49c1c2" />
          </linearGradient>
        </defs>
        <rect className="cls-3" x="0" y="0" width="1200" height="400" />
        <g>
          <path className="cls-1" d="M1005.84,187.12h22.07c5.72,0,10.35,4.64,10.35,10.35,0,.72-.08,1.44-.23,2.15l-8.69,40.89c-1.02,4.78-5.24,8.19-10.12,8.2h-22.07c-5.72,0-10.35-4.64-10.35-10.35,0-.72,.08-1.44,.23-2.15l8.7-40.89c1.01-4.78,5.23-8.2,10.11-8.2Z" />
          <path className="cls-1" d="M1028.12,82.32h22.07c5.72,0,10.35,4.64,10.35,10.35,0,.72-.08,1.44-.23,2.15l-8.7,40.89c-1.01,4.78-5.23,8.19-10.11,8.19h-22.07c-5.71,0-10.34-4.62-10.35-10.33,0-.72,.08-1.45,.23-2.16l8.69-40.9c1.02-4.77,5.24-8.19,10.12-8.19Z" />
          <path className="cls-2" d="M1108.8,156.4l-4.77,22.53c-1.01,4.78-5.23,8.2-10.12,8.19h-40.43c-5.72,0-10.35-4.64-10.34-10.36,0-.72,.08-1.43,.22-2.13l4.79-22.53c1.02-4.78,5.24-8.19,10.12-8.2h40.43c5.72,.01,10.34,4.66,10.33,10.37,0,.71-.08,1.43-.23,2.13Z" />
          <path className="cls-4" d="M1004.03,156.4l-4.79,22.53c-1.02,4.78-5.24,8.19-10.12,8.19H444.86c-5.71,0-10.34-4.62-10.35-10.33,0-.72,.08-1.45,.23-2.16l4.79-22.53c1.01-4.78,5.23-8.2,10.12-8.2H993.88c5.72-.01,10.36,4.61,10.38,10.32,0,.73-.07,1.46-.23,2.18Z" />
          <polygon className="cls-1" points="175 283.31 145.99 226.11 110.44 226.11 90.98 317.66 120.39 317.66 132.46 260.86 161.02 317.66 174.51 317.66 227.09 260.86 215.01 317.66 244.55 317.66 264.01 226.11 228.46 226.11 175 283.31" />
          <path className="cls-1" d="M516.78,233.92c-3.14-2.72-6.85-4.71-10.86-5.81-4.9-1.4-9.98-2.07-15.08-2h-77.79l-4.32,20.32h72.71c4.55,0,7.81,.98,9.8,2.94,1.99,1.96,2.57,4.86,1.76,8.69l-5.88,27.66c-.6,3.5-2.56,6.63-5.45,8.69-2.82,1.96-6.5,2.94-11.05,2.94h-72.71l-4.32,20.32h77.79c5.36,.03,10.71-.62,15.91-1.94,4.72-1.17,9.23-3.11,13.32-5.74,3.84-2.49,7.17-5.69,9.82-9.42,2.72-3.85,4.61-8.23,5.55-12.85l6.73-31.68c1.03-4.19,1-8.58-.09-12.76-1.03-3.61-3.05-6.85-5.84-9.36Z" />
          <path className="cls-1" d="M309.34,249.36c2.82-1.96,6.5-2.94,11.05-2.94h72.71l4.32-20.32h-77.79c-5.36-.03-10.71,.62-15.91,1.94-4.73,1.17-9.23,3.12-13.32,5.75-3.84,2.49-7.17,5.69-9.82,9.42-2.71,3.85-4.6,8.22-5.54,12.83l-6.73,31.68c-1.03,4.19-1,8.58,.09,12.76,1.03,3.6,3.05,6.85,5.83,9.36,3.14,2.72,6.85,4.71,10.86,5.81,4.9,1.4,9.98,2.07,15.08,2h77.81l4.32-20.32h-72.73c-4.55,0-7.81-.98-9.8-2.94-1.99-1.96-2.58-4.85-1.79-8.68l.76-3.6h86.5l4.87-20.32h-87.02l.8-3.74c.6-3.5,2.56-6.62,5.45-8.69Z" />
          <path className="cls-1" d="M719.64,228.11c-4.73,1.21-9.23,3.17-13.33,5.81-3.83,2.48-7.15,5.65-9.81,9.36-2.7,3.82-4.56,8.17-5.47,12.76l-6.74,31.68c-1.03,4.22-1,8.62,.08,12.83,1.02,3.64,3.06,6.91,5.89,9.42,3.17,2.72,6.91,4.68,10.94,5.75,4.96,1.36,10.08,2.01,15.22,1.94h39.3c5.36,.03,10.71-.62,15.91-1.94,4.71-1.17,9.19-3.11,13.27-5.73,3.84-2.49,7.17-5.69,9.82-9.42,2.72-3.85,4.6-8.22,5.54-12.83l4.63-21.79h-29.41l-4.2,19.78c-.66,3.41-2.59,6.45-5.41,8.49-2.85,2.1-6.55,3.15-11.1,3.14h-29.13c-4.55,0-7.82-.98-9.8-2.94-1.98-1.96-2.57-4.86-1.76-8.69l6.33-29.8c2.15-6.33,7.46-9.49,15.92-9.49h72.7l4.31-20.32h-77.77c-5.37-.04-10.73,.63-15.93,1.99Z" />
          <path className="cls-1" d="M940.03,243.28c-1.04-3.62-3.09-6.86-5.9-9.36-3.17-2.71-6.91-4.7-10.93-5.81-4.95-1.41-10.07-2.08-15.21-2h-39.3c-5.37-.03-10.73,.64-15.93,2-4.73,1.21-9.23,3.18-13.34,5.81-3.82,2.48-7.15,5.66-9.8,9.36-2.71,3.82-4.59,8.17-5.52,12.76l-6.74,31.68c-1.03,4.22-1,8.62,.08,12.83,1.01,3.63,3.03,6.89,5.82,9.42,3.14,2.72,6.85,4.69,10.87,5.75,4.92,1.35,10,2.01,15.1,1.94h39.29c5.39,.03,10.77-.62,16-1.94,4.76-1.17,9.29-3.1,13.43-5.73,3.87-2.48,7.22-5.68,9.89-9.42,2.71-3.85,4.6-8.22,5.54-12.83l6.73-31.68c1.04-4.2,1.01-8.59-.08-12.78Zm-29.74,14.77l-5.88,27.66c-.6,3.5-2.57,6.63-5.46,8.69-2.82,1.96-6.49,2.94-11,2.94h-29.19c-4.55,0-7.82-.98-9.8-2.94-1.98-1.96-2.57-4.86-1.77-8.69l5.89-27.66c.6-3.5,2.56-6.62,5.45-8.69,2.82-1.96,6.49-2.94,11-2.94h29.14c4.54,0,7.81,.98,9.8,2.94,1.99,1.96,2.6,4.86,1.81,8.69h.01Z" />
          <path className="cls-2" d="M641.75,226.12h.01l-12.72,59.59c-.6,3.5-2.57,6.63-5.46,8.69-2.82,1.96-6.49,2.94-11,2.94h-29.19c-4.55,0-7.82-.98-9.8-2.94-1.98-1.96-2.57-4.86-1.77-8.69l12.73-59.59h-29.58l-12.98,61.6c-1.03,4.22-1,8.62,.08,12.83,1.01,3.63,3.03,6.89,5.82,9.42,3.14,2.72,6.85,4.69,10.87,5.75,4.92,1.35,10,2.01,15.1,1.94h39.29c5.39,.03,10.77-.62,16-1.94,4.76-1.17,9.29-3.1,13.43-5.73,3.87-2.48,7.22-5.68,9.89-9.42,2.71-3.85,4.6-8.22,5.54-12.83l12.97-61.6-29.23-.02Z" />
        </g>
      </svg>
    </div>
  );
};

export default MedugoSVG;