import MedugoSVG from "assets/images/MedugoSVG";


const Logo = () => {
  return (
    <MedugoSVG />
  );
};

export default Logo;
