import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import { SET_USER_NAME } from "store/actions";

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const hlogPersonId = searchParams.get("PersonId");
  console.log("AAAPPP", hlogPersonId);

  useEffect(() => {
    if (
      customization.userData?.hlogPersonId === "" &&
      searchParams.get("PersonId") === null
    ) {
      const data = JSON.parse(localStorage.getItem("userData"));
      if (data && data?.hlogPersonId !== null) {
        dispatch({ type: SET_USER_NAME, data });
      }
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;
